import React, { SVGProps } from "react";
import cx from "classnames";

export const Caret = (props?: SVGProps<SVGSVGElement> & { open?: boolean }): React.ReactElement => {
  return (
    <svg
      className={cx("w-4 h-4 ml-2 caret", props?.open && "rotate-180")}
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
    </svg>
  );
};
